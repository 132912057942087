
<template>
  <div class="confirm" @click="lockSeat()">
    <div class="dib c-d1" v-show="selectedSeat.length != 0">
      {{ selectedSeat.length }}张
    </div>
    <div class="dib c-d2" v-show="selectedSeat.length != 0">
      {{ $utils.getPricePoint(this.keepTwoDecimalFull(sale_price)) }}点
    </div>
    <!-- <div class="dib c-d1" v-show="selectedSeat.length != 0">
      省￥{{ this.keepTwoDecimalFull(save_price) }}&nbsp;<i style="text-decoration: line-through">市场价￥{{ this.keepTwoDecimalFull(chPrice) }}</i>
    </div> -->
    {{ selectedSeat.length != 0 ? "确认" : "请先" }}选座

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    propSelectedSeat: Array,
    propSeatList: Array,
    propIsCheap: Number,
    propServiceFee: String,
    propPlanId: String,
    propsId: String,
    propcId: String,
    propCardId: String,
    // xtips: Number,
    proptips: Boolean,
    proptocom: Boolean,
    propfilm_id: String,
    propHallName: String,
    propShowDate: String,
    propShowTime: String,
    propcinema: String,
    propEndTime: String,
    propiscOrder: Boolean,
    movieName: String,
    showtype: String,
    movieAddress: String,
    moviePicUrl: String,
  },
  data() {
    // 这里存放数据
    return {
      selectedSeat: this.propSelectedSeat,
      seatList: this.propSeatList,
      show: false,
      // ctocom: true
    }
  },
  // 监听属性 类似于data概念
  computed: {
    chPrice() {
      // 市场原价
      let totalPrice = 0
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        totalPrice += iterator.price
      }
      return totalPrice
    },
    sale_price() {
      // 售卖价格
      let totalPrice = 0
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        totalPrice += parseFloat(iterator.price)
      }
      return parseFloat(totalPrice).toFixed(2)
    },
    save_price() {
      // 省多少
      let totalPrice = 0
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        totalPrice += iterator.save_price
      }
      return totalPrice
    },
    seatIdList() {
      let seatIdList = []
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        seatIdList.push(iterator.seatNo)
      }
      return seatIdList
    },

    seatIdListStr() {
      let seatIdList = ''
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        seatIdList = seatIdList + iterator.seatNo + ","
      }
      return seatIdList
    },
    selseatList() {
      let selseatList = []
      let selectedSeat = this.selectedSeat
      for (const iterator of selectedSeat) {
        selseatList.push({ 'row': iterator.gRow, 'col': iterator.gCol })
      }
      return selseatList
    }
  },
  // 监控data中的数据变化
  watch: {
    propSelectedSeat: function () {
      this.selectedSeat = this.propSelectedSeat
    },
    propSeatList: function () {
      this.seatList = this.propSeatList
    },
    propIsCheap: function (value) {
      this.isCheap = value
    },
    propServiceFee: function (value) {
      this.serviceFee = value
    },
    propPlanId: function (value) {
      this.planId = value
    },
    propiscOrder: function () {
      if (this.propiscOrder) {
        this.createOrder()
      } else {
        this.$Toast.fail('下单参数异常~')
      }
    }
  },

  // 方法集合
  methods: {
    //检测座位是否相邻
    checkAdjacent: function () {
      let canCheck = true
      this.selectedSeat.forEach(element => {
        let result = this.oneSeatCheck(element)
        if (!result) {
          canCheck = false
        }
      });
      return canCheck
    },
    oneSeatCheck(val) {
      let canCheck = true
      //该座位行的所有列数
      let seats = []
      let columnNos = []
      this.seatList.forEach(element => {
        if (val.rowNo == element.rowNo) {
          seats.push(element)
          columnNos.push(element.columnNo)
        }
      });
      seats.forEach((item, index) => {
        if (val.seatId === item.seatId) {
          //不靠边
          if (index > 0 && index < (seats.length - 1) && columnNos.includes(val.columnNo + 1) && columnNos.includes(val.columnNo - 1)) {
            //被选 或者不能选
            if ((seats[index + 1].nowIcon != seats[index + 1].selectedIcon && seats[index + 1].nowIcon != seats[index + 1].soldedIcon)) {
              if (columnNos.includes(val.columnNo + 2)) {
                if (seats[index + 2].nowIcon != seats[index + 2].selectedIcon && seats[index + 2].nowIcon != seats[index + 2].soldedIcon) {

                } else {
                  canCheck = false
                }
              } else {
                canCheck = false
              }
            }
            if ((seats[index - 1].nowIcon != seats[index - 1].selectedIcon && seats[index - 1].nowIcon != seats[index - 1].soldedIcon)) {
              if (columnNos.includes(val.columnNo - 2)) {
                if (seats[index - 2].nowIcon != seats[index - 2].selectedIcon && seats[index - 2].nowIcon != seats[index - 2].soldedIcon) {

                } else {
                  canCheck = false
                }
              } else {
                canCheck = false
              }
            }
          }
        }
      });
      return canCheck;
    },

    lockSeat: function () {
      let _this = this
      // 防止连点
      let check = _this.$once(new Date().getTime())
      if (!check) {
        return
      }
      if (_this.selectedSeat.length === 0) {
        this.$Toast.fail('请至少选择一个座位~')
        return
      }
      let result = _this.checkAdjacent()
      // 开始计算是否留下空位 ------------ 结束
      if (!result) {
        // 如果 result 为false
        this.$Toast.fail('请选择相邻座位')
      } else {
        if (_this.chPrice === 0) {
          this.$Toast.fail('锁座失败了~,金额为0')
          return
        }
        // 允许锁座
        this.createOrder()
      }
    },
    createOrder() {
      localStorage.setItem('film_id', this.propfilm_id)
      localStorage.setItem('seatList', JSON.stringify(this.propSelectedSeat))
      localStorage.setItem('sale_price', this.$utils.getPricePoint(this.keepTwoDecimalFull(this.sale_price)))
      this.lockseat()
    },


    lockseat() {
      const form = {
        cityCode: this.cityCode,
        price: this.propSelectedSeat.length > 0 ? this.propSelectedSeat[0].costPrice : '',
        costPrice: this.propSelectedSeat.length > 0 ? this.propSelectedSeat[0].settlePrice : '',
        filmId: this.propfilm_id,
        filmName: this.movieName,
        productNum: this.propSelectedSeat.length,
        productTotal: this.$utils.getPricePoint(this.sale_price),
        seatInfoList: this.getFormSeat(),
        orderDetail: this.getMovieDetail(),
      }
      localStorage.setItem('lockseatform', JSON.stringify(form))
      this.toConfig()
    },
    getMovieDetail() {
      let moveDetail = {}
      moveDetail.movieName = this.movieName
      moveDetail.hallType = this.showtype
      moveDetail.cinemaName = this.propcinema
      moveDetail.cinemaAddress = this.movieAddress
      moveDetail.hallName = this.propHallName
      moveDetail.seatNames = this.seatIdListStr
      moveDetail.moviePlayTime = this.propShowDate + ' ' + this.propShowTime
      moveDetail.moviePicUrl = this.moviePicUrl
      moveDetail.cardId = this.cardId
      return JSON.stringify(moveDetail)
    },
    getFormSeat() {
      let setList = []
      this.propSelectedSeat.forEach(element => {
        let seat = {}
        seat.seatId = element.seatId
        seat.showPrice = element.price
        seat.costPrice = element.settlePrice
        setList.push(seat)
      });
      return setList
    },
    toConfig() {
      this.$router.push({
        path: "/ConfigOrder",
      });
    },
    toPay(orderId) {
      this.$router.push({
        path: "/Cashier",
        query: {
          order: orderId,
        },
      });
    },
    // 检查每个座位是否会留下空位
    checkSeat: function (element) {
      // 标准为 1.左右侧都必须保留 两格座位 + 最大顺延座位(也就是已选座位减去自身)
      // 2.靠墙和靠已售的座位一律直接通过
      const checkNum = 2 + this.selectedSeat.length - 1
      const gRowBasic = element.gRow
      const gColBasic = element.gCol
      let otherLoveSeatIndex = element.otherLoveSeatIndex
      if (otherLoveSeatIndex != null) {
        // 如果是情侣座 不检测
        return true
      }
      // 检查座位左侧
      let left = this.checkSeatDirection(gRowBasic, gColBasic, checkNum, '-')
      // 如果左侧已经检查出是靠着过道直接 返回true
      if (left === 'special') {
        return true
      }
      // 检查座位右侧
      let right = this.checkSeatDirection(gRowBasic, gColBasic, checkNum, '+')
      if (right === 'special') {
        // 无论左侧是否是什么状态 检查出右侧靠着过道直接 返回true
        return true
      } else if (right === 'normal' && left === 'normal') {
        // 如果左右两侧都有富裕的座位 返回true
        return true
      } else if (right === 'fail' || left === 'fail') {
        // 如果左右两侧都是不通过检测 返回false
        return false
      }
      return true
    },
    // 检查左右侧座位满足规则状态
    checkSeatDirection: function (gRowBasic, gColBasic, checkNum, direction) {
      // 空位个数
      let emptySeat = 0
      let x = 1 // 检查位置 只允许在x的位置出现过道,已售,维修
      for (let i = 1; i <= checkNum; i++) {
        let iter // 根据 gRow gCol direction 找出检查座位左边按顺序排列的checkNum
        if (direction === '-') {
          iter = this.seatList.find((el) => (el.gRow === gRowBasic && el.gCol === gColBasic - i))
        } else if (direction === '+') {
          iter = this.seatList.find((el) => (el.gRow === gRowBasic && el.gCol === gColBasic + i))
        }
        if (x === i) {
          if (iter === undefined) {
            // 过道
            return 'special'
          }
          if (iter.nowIcon === iter.soldedIcon || iter.nowIcon === iter.fixIcon) {
            // 已售或者维修
            return 'special'
          }
          if (iter.nowIcon === iter.selectedIcon) {
            // 已选 顺延一位
            x++
            continue
          }
        } else {
          if (iter === undefined) {
            // 过道
            return 'fail'
          }
          if (iter.nowIcon === iter.soldedIcon ||
            iter.nowIcon === iter.fixIcon ||
            iter.nowIcon === iter.selectedIcon) {
            // 已售或者维修
            return 'fail'
          }
        }
        emptySeat++
        if (emptySeat >= 2) {
          return 'normal'
        }
      }
    },
    // 四舍五入保留2位小数（不够位数，则用0替补）
    keepTwoDecimalFull: function (num) {
      let result = parseFloat(num)
      if (isNaN(result)) {
        // alert('传递参数错误，请检查！')
        return false
      }
      result = Math.round(num * 100) / 100
      let sxa = result.toString() // 将数字转换为字符串
      let posdecimal = sxa.indexOf('.') // 小数点的索引值
      // 当整数时，posdecimal=-1 自动补0
      if (posdecimal < 0) {
        posdecimal = sxa.length
        sxa += '.'
      }
      // 当数字的长度< 小数点索引+2时，补0
      while (sxa.length <= posdecimal + 2) {
        sxa += '0'
      }
      return sxa
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.cityCode = localStorage.getItem("cityCode");
    this.cardId = localStorage.getItem("cardId");
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  // 生命周期 - 更新之后
  updated() { },
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped='scoped'>
// @import url(); 引入公共css类
.confirm {
  border-radius: 1.5rem;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: calc(100% - 50px);
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);

  text-align: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 0;
  margin-bottom: 1rem;
}

.dib {
  display: inline-block;
}

.c-d1 {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.c-d2 {
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
