var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-area",style:({ width: _vm.seatAreaWidthRem * 2.5 + 'rem', height: _vm.seatAreaHeightRem + 'rem' })},[_vm._m(0),_c('div',{staticClass:"screen-text-top"},[(_vm.propHallName.length > 0 && _vm.showTag)?_c('div',{staticClass:"screen-text"},[_vm._v(" 您选择的是"),_c('span',{staticStyle:{"color":"#CD4B44"}},[_vm._v(_vm._s(_vm.propHallName))]),_vm._v("的场次 ")]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.thumbnailShow),expression:"thumbnailShow"}],staticClass:"thumbnail",style:({ transform: 'scale(' + _vm.seatScale + ')', width: _vm.thumbnailWidthRem * 2.5 + 'rem', height: _vm.thumbnailHeighthRem * 2.5 + 'rem' })},[_c('div',{staticClass:"thumbnail-border",style:({ transform: 'scale(' + _vm.scalereciprocal + ')', top: _vm.topthumbnail + 'rem', left: _vm.leftthumbnail + 'rem' })}),_vm._t("thumbnail-seat-solt")],2),_c('v-touch',{ref:"pinchAndPan",staticClass:"box",style:({
      transform: 'scale(' + _vm.scale + ')', transformOrigin: _vm.transformOrigin, top: _vm.top * 2.5 + 'rem', left: _vm.left + 'rem',
      width: _vm.seatAreaWidthRem * 2.5 + 'rem', height: _vm.seatAreaHeightRem * 2.5 + 'rem'
    }),attrs:{"pinch-options":{ threshold: 0.09 },"pan-options":{ threshold: 0.01 }},on:{"pinchout":_vm.pinchout,"pinchin":_vm.pinchin,"panmove":_vm.panmove,"panstart":_vm.panstart,"panend":_vm.panend}},[_vm._t("seat-area-solt")],2),_c('div',{staticClass:"seat-tool-parent",style:({ height: _vm.seatAreaHeightRem / 2 + 'rem' })},[_c('div',{staticClass:"seat-tool",style:({
      transform: 'scale(' + _vm.seatScale + ')', transformOrigin: _vm.transformOriginTool, marginTop: _vm.seatToolMargin * 2.5 + 'rem',
    })},_vm._l((_vm.seatToolArr),function(item,index){return _c('div',[_c('div',{key:'seat-tool' + index,staticClass:"seat-tool-item",style:({ height: _vm.seatHeightWithScale * 2.5 + 'rem', width: _vm.seatToolWidthWithScale * 5 + 'rem', lineHeight: _vm.seatHeightWithScale * 2.5 + 'rem', fontSize: _vm.seatToolFontSize * 2.5 + 'rem' })},[_vm._v(" "+_vm._s(item)+" ")])])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen"},[_c('div',{staticClass:"screen-text-t"},[_vm._v(" 银幕方向 ")])])
}]

export { render, staticRenderFns }