<template>
  <div class="wapper">
    <!--头部 开始-->
    <!-- <header-view :titleText="movieName" @backHandleClick="back"></header-view> -->
    <!--头部 结束-->
    <!--排期详情和座位上方示例图 开始-->
    <plan-detail :propHallName="hallName" :propShowDate="showDate" :propShowTime="showTime">
      <template v-for="seatTypeItem in seatsPartition">
        <div class="seat-detail-item">
          <img class="seatTypeClass" :src="seatTypeItem.icon" />
          <span class="seatTypeClass ml5">{{ $utils.getPricePoint(seatTypeItem.price) }}</span>
        </div>
      </template>
    </plan-detail>
    <!--排期详情和座位上方示例图 结束-->
    <seat-area :propThumbnailAreaWidth="thumbnailBoxWidth" :propThumbnailAreaHeight="thumbnailBoxHeight"
      :propYMax="yMax" :propSeatScale="seatScale" :propSeatHeight="positionDistin" :propSeatToolArr="seatToolArr"
      :propSeatAreaWidthRem="seatAreaWidthRem" :propSeatAreaHeightRem="seatAreaHeightRem"
      :propSeatBoxHeight="seatBoxHeight" :propMiddleLine="middleLine" :propHorizontalLine="horizontalLine"
      :propHallName="tigTime" ref="seatArea">
      <!--以下为缩略座位图具名插槽 开始-->
      <template slot="thumbnail-seat-solt">
        <div v-for="(seatItem, index) in seatList">
          <div class="thumbnailSeatClass" :key="'thumbnail' + index" :style="{
      height: thumbnailHeight * 2.5 + 'rem',
      width: thumbnailWidth * 2.5 + 'rem',
      background: thumbnailBackgroud(seatItem),
      top: seatItem.rowNo * thumbnailPositionDistin * 2.5 + 'rem',
      left: seatItem.columnNo * thumbnailPositionDistin * 2.5 + 'rem',
    }"></div>
        </div>
      </template>
      <!--以上为缩略座位图具名插槽 结束-->
      <!--以下为座位图具名插槽 开始-->
      <template slot="seat-area-solt">
        <div class="seatBox" :style="{
      transform: 'scale(' + seatScale + ')',
      height: seatBoxHeight * 2.5 + 'rem',
      width: seatBoxWidth * 2.5 + 'rem',
      marginLeft: seatBoxCenterMargin * 2.5 + 'rem',
    }">
          <!--中轴线-->
          <div v-show="seatList.length > 0" class="middle-line"
            :style="{ height: seatBoxHeight * 2.5 + 'rem', left: middleLine * 2.5 + 'rem' }"></div>
          <div v-for="(seatItem, index) in seatList">
            <div class="seatClass" @click.prevent="clickSeat(index)" :key="index" :style="{
      height: height * 2.5 + 'rem',
      width: width * 2.5 + 'rem',
      top: seatItem.rowNo * positionDistin * 2.5 + 'rem',
      left: seatItem.columnNo * positionDistin * 2.5 + 1.25 + 'rem',
    }">
              <img class="seatImgClass" :seatId="index" :cNo="seatItem.seatNo" :seatIndex="index"
                :src="seatItem.nowIcon" />
            </div>
          </div>
        </div>
      </template>
      <!--以上为座位图具名插槽 结束-->
    </seat-area>
    <div class="bo-seatType">
      <div v-for="seatTypeItem in seatTypeList">
        <div class="seat-detail-item2">
          <img class="seatTypeClass" :src="seatTypeItem.icon" />
          <span class="seatTypeClass ml5">{{ seatTypeItem.name }}</span>
        </div>
      </div>
    </div>
    <div class="bo-txt">
      <!-- 失活的组件将会被缓存！-->
      <div class="film-info">
        <div class="film-name">{{ movieName }}</div>
        <div class="film-txt"><span class="film-txt1">{{ showDate2 }}</span><span class="film-txt2">{{ showTime
            }}开始{{ endTime }}散场</span> <span class="film-txt2">{{ show_version_type }}</span></div>

      </div>
      <keep-alive>
        <component v-bind:is="selectedTabComponents" :propSeatList="seatList" :propSelectedSeat="selectedSeatList"
          @quickSelect="processUnSelected" @cancelSelect="processSelected"></component>
      </keep-alive>
      <!-- <tips :proptocom="tocom" @tips="tips" @createOrder="tocreateOrder"></tips> -->
      <div class="confirmpd">
        <confirm-lock :propSelectedSeat="selectedSeatList" :propSeatList="seatList" :propsId="sId" :propcId="cId"
          :propCardId="form.cardId" :proptips="ctips" :propfilm_id="film_id" :propHallName="hallName"
          :propShowDate="showDate" :propShowTime="showTime" :propEndTime="endTime" :propcinema="cinema_name"
          :propiscOrder="iscreateOrder" @ctocom="xgtocom" :movieName="movieName" :showtype="show_version_type"
          :movieAddress="movieAddress" :moviePicUrl="moviePicUrl" @showTag="showTags" ref="confirlock"></confirm-lock>
      </div>
    </div>
    <!-- <get-know  @closed="closeds" @goBuy="sitSeat"></get-know> -->
    <have-order v-if="showtag" :movieDate="movieDate" @closed="closeds"> </have-order>
  </div>
</template>
<script>
import SeatArea from '../../components/movie/SeatArea'
import PlanDetail from '../../components/movie/PlanDetail'
import SelectedTab from '../../components/movie/SelectedTab'
import QuickSelectTab from '../../components/movie/QuickSelectTab'
import ConfirmLock from '../../components/movie/ConfirmLock'
import HaveOrder from '../../components/movie/HaveOrder'
import HeaderView from '@/components/Header'
import Tips from '../../components/movie/Tips'
import { Toast } from 'vant'
import '../../config/rem'

export default {
  name: 'Ticket',
  data() {
    return {
      showtag: false,
      seatList: [], // 座位对象list
      maxSeatPrice: null,
      seatsPartition: [],
      seatTypeList: [
        {
          name: "可选",
          type: "0",
          seats: 1,
          icon: require('@/assets/images/seat_unselected1.png'),
          isShow: "1",
          position: "up"
        },
        {
          name: "已选",
          type: "0-1",
          seats: 1,
          icon: require('@/assets/images/seat_selected.png'),
          isShow: "1",
          position: "up"
        },
        {
          name: "已售",
          type: "0-2",
          seats: 1,
          icon: require('@/assets/images/seat_cantselected.png'),
          isShow: "1",
          position: "up"
        },

      ], // 座位类型list
      movieName: '', // 展示用 电影名称 接口获取
      hallName: '', // 展示用 影厅名称 接口获取
      showDate: '', // 展示用 开始日期 接口获取
      showDate2: '', // 展示用 开始日期 接口获取
      showTime: '', // 展示用 开始时间 接口获取
      endTime: '', // 展示用 结束时间 接口获取
      positionDistin: 1, // 每个座位偏移距离
      tigTime: '',
      width: 1, // 每个座位的宽
      height: 1, // 每个座位的高
      thumbnailWidth: 0.1, // 缩略图每个座位的宽
      thumbnailHeight: 0.1, // 缩略图每个座位的高
      thumbnailPositionDistin: 0.15, // 缩略图每个座位偏移距离

      // 设备 rem计算值
      // seatAreaWidthRem: (document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth) / 100,

      seatAreaWidthRem: 10, // 座位区域横向rem最大值 用于和 seatAreaHeightRem 共同计算区域缩放比例
      selectedSeatList: [], // 已选择座位
      maxSelect: 4, // 最大选择座位数量 改动可改变最大选择座位数
      movieAddress: '',//影院地址
      load: false, // 加载dom的控制
      // tips: this.tips, // 默认自动调座
      sId: '',

      ctips: false,
      film_id: '',
      cinema_name: '',
      cId: '', // 影院id
      show_version_type: '',
      tocom: false,
      iscreateOrder: false,
      form: {
        areaFlag: '1',
        showId: '',
        cardId: '',
        cinemaId: '',
        cityCode: '',
        ticketType: ''
      },
      moviePicUrl: null,
      movieDate: null,
    };
  },



  components: {
    SeatArea,
    PlanDetail,
    HeaderView,
    SelectedTab,
    QuickSelectTab,
    ConfirmLock,
    Tips,
    HaveOrder
  },
  mounted() {
    this.form.cityCode = localStorage.getItem("cityCode");
    this.movieName = this.$route.query.movieName
    this.hallName = this.$route.query.hallName
    this.film_id = this.$route.query.fimId
    this.form.cinemaId = this.$route.query.cinemaId
    this.cinema_name = this.$route.query.cinema_name
    this.form.showId = this.$route.query.id
    this.movieAddress = this.$route.query.movieAddress
    this.form.ticketType = localStorage.getItem("entrCode");
    this.form.cardId = localStorage.getItem("cardId");
    this.showDate = this.$route.query.showDate
    this.showTime = this.$route.query.startTime
    this.endTime = this.$route.query.endTime
    this.moviePicUrl = this.$route.query.moviePicUrl
    this.show_version_type = this.$route.query.showVersionType
    document.title = `${this.cinema_name}( ${this.hallName})`
    this.getSeatList()
    this.initTag()
    this.queryUnpaidOrderInfo()
  },

  methods: {
    queryUnpaidOrderInfo() {
      let userId = JSON.parse(localStorage.getItem("userInfo")).id
      this.$api
        .queryUnpaidOrderInfo(userId)
        .then((res) => {
          if (res.code == 0) {
            this.movieDate = res.data
            if (this.movieDate.orderDetail) {
              this.showtag = true
            }
          }
        })
        .catch((err) => {
        });
    },
    getTimeQ(str) {
      return this.$moment(str).format("YYYY.MM.DD")
    },
    initTag() {
      this.showDate2 = this.getTimeQ(this.showDate)
      let newDate = this.$moment().format('YYYY-MM-DD')
      //计算相差多少天，参数可传：day 、second、minute
      let dateRange = this.$moment(this.showDate).diff(this.$moment(newDate), 'day')
      if (dateRange > 0) {
        if (dateRange == 1) {
          this.tigTime = `${this.$moment(this.showDate).format("MM-DD")}明天`
        } else if (dateRange == 2) {
          this.tigTime = `${this.$moment(this.showDate).format("MM-DD")}后天`
        } else
          this.tigTime = `${this.$moment(this.showDate).format("MM-DD")}`
      }
    },
    closeds() {
      this.showtag = false
      this.getSeatList()
    },
    showTags() {
      this.showtag = true
    },
    getSeatList() {
      // mock数据
      this.$api.queryShowSeats(this.form)
        .then((response) => {
          if (response.code == 1) {
            this.$Toast(response.msg);
            return
          }
          this.sId = this.form.showId
          this.cId = this.form.cinemaId

          // 最多可以下的座位数量
          if (response.data.restrictions) {
            this.maxSelect = response.data.restrictions
          }
          if (response.data.maxSeatPrice) {
            this.maxSeatPrice = response.data.maxSeatPrice
          }
          var resSeatList = response.data.seats
          this.seatsPartition = response.data.seatsPartition
          this.seatsPartition = this.seatsPartition.sort((a, b) => a.seatId - b.seatId)
          this.seatsPartition.forEach((item, index) => {


            if (index == 0) {
              item.icon = require('@/assets/images/seat_unselected1.png')
            } else if (index == 1) {
              item.icon = require('@/assets/images/seat_unselected2.png')
            } else if (index == 2) {
              item.icon = require('@/assets/images/seat_unselected3.png')
            } else if (index == 3) {
              item.icon = require('@/assets/images/seat_unselected4.png')
            } else {
              item.icon = require('@/assets/images/seat_unselected5.png')
            }
          });
          // 座位处理 -------开始
          if (!resSeatList) {
            return
            // this.$router.go(0)
          }
          resSeatList.forEach((element, index) => {
            element.selectedIcon = require('@/assets/images/seat_selected.png')
            element.soldedIcon = require('@/assets/images/seat_cantselected.png')


            this.seatsPartition.forEach(item => {
              if (item.seatId == element.areaId) {
                element.nowIcon = item.icon
                element.defautIcon = item.icon
              }
            });
            if (element.lovestatus === '1') {
              element.nowIcon = require('@/assets/images/seat_love.png')
              element.defautIcon = require('@/assets/images/seat_love.png')
              element.otherLoveSeatIndex = index + 1
              element.otherLoveSeatId = resSeatList[index + 1].seatId
            } else if (element.lovestatus === '2') {
              element.nowIcon = require('@/assets/images/seat_love.png')
              element.defautIcon = require('@/assets/images/seat_love.png')
              element.otherLoveSeatIndex = index - 1
              element.otherLoveSeatId = resSeatList[index - 1].seatId
            }
            if (element.status == 'LK') {
              // element.defautIcon = element.soldedIcon
              element.nowIcon = element.soldedIcon
              element.canClick = false
            } else {
              element.canClick = true
            }
          })
          this.seatList = resSeatList
          console.log('seatList    ', this.seatList);
        }, err => {
          console.log(err)
        })
    },
    // 点击每个座位触发的函数
    clickSeat: function (index) {
      if (this.maxSeatPrice) {
        if (parseFloat(this.seatList[index].price) > parseFloat(this.maxSeatPrice)) {
          this.$Toast('该座位价格超过福利卡可用价格');
          return
        }
      }

      if (this.seatList[index].canClick) {
        if (this.seatList[index].nowIcon === this.seatList[index].selectedIcon) {
          this.processSelected(index)
        } else {
          this.processUnSelected(index)
        }
      }
    },
    // 处理已选的座位
    processSelected: function (index) {
      let _selectedSeatList = this.selectedSeatList
      let otherLoveSeatIndex = this.seatList[index].otherLoveSeatIndex
      if (otherLoveSeatIndex) {
        // 如果是情侣座位
        // 改变这些座位的图标为初始图标
        this.$set(this.seatList[index], 'nowIcon', this.seatList[index].defautIcon)
        this.$set(this.seatList[otherLoveSeatIndex], 'nowIcon', this.seatList[otherLoveSeatIndex].defautIcon)
        for (const key in _selectedSeatList) {
          // 移除id一样的座位
          if (_selectedSeatList[key].seatId === this.seatList[index].seatId) {
            _selectedSeatList.splice(key, 1)
          }
        }
        // 移除对应情侣座位
        for (const key in _selectedSeatList) {
          if (_selectedSeatList[key].seatId === this.seatList[otherLoveSeatIndex].seatId) {
            _selectedSeatList.splice(key, 1)
          }
        }
      } else {
        // 改变这些座位的图标为初始图标 并 移除id一样的座位
        this.$set(this.seatList[index], 'nowIcon', this.seatList[index].defautIcon)
        for (const key in _selectedSeatList) {
          if (_selectedSeatList[key].seatId === this.seatList[index].seatId) {
            _selectedSeatList.splice(key, 1)
          }
        }
      }
    },
    // 处理未选择的座位
    processUnSelected: function (index) {
      // 如果是选择第一个座位 放大区域并移动区域 突出座位 增加用户体验
      if (this.selectedSeatList.length === 0) {
        let top = ((this.seatList[index].rowNo * this.positionDistin) - this.horizontalLine) * this.seatScale
        let left = ((this.seatList[index].columnNo * this.positionDistin) - this.middleLine) * this.seatScale
        top = top > 0 ? -top - this.positionDistin : -top + this.positionDistin
        left = left > 0 ? -left - this.positionDistin : -left + this.positionDistin
        this.$refs.seatArea.changeScale()
        this.$refs.seatArea.changePosition(top, left * 2.3)
      }
      let _selectedSeatList = this.selectedSeatList
      let otherLoveSeatIndex = this.seatList[index].otherLoveSeatIndex
      console.log('otherLoveSeatIndex   ',otherLoveSeatIndex);
      if (otherLoveSeatIndex) {
          // 如果选中的是情侣座位 判断选择个数不大于 maxSelect
          if (_selectedSeatList.length >= this.maxSelect - 1) {
            this.$Toast('最多只能选择' + this.maxSelect + '个座位哦~')
            return
          }
          // 改变这些座位的图标为已选择图标
          this.$set(this.seatList[index], 'nowIcon', this.seatList[index].selectedIcon)
          this.$set(this.seatList[otherLoveSeatIndex], 'nowIcon', this.seatList[otherLoveSeatIndex].selectedIcon)
          // 记录 orgIndex属性 是原seatList数组中的下标值
          this.seatList[index].orgIndex = index
          this.seatList[otherLoveSeatIndex].orgIndex = otherLoveSeatIndex
          // 把选择的座位放入到已选座位数组中
          _selectedSeatList.push(this.seatList[index])
          _selectedSeatList.push(this.seatList[otherLoveSeatIndex])
      } else {
        if (_selectedSeatList.length >= this.maxSelect) {
          this.$Toast('最多只能选择' + this.maxSelect + '个座位哦~')
          return
        }
        // 改变这些座位的图标为已选择图标
        this.$set(this.seatList[index], 'nowIcon', this.seatList[index].selectedIcon)
        // 记录 orgIndex属性 是原seatList数组中的下标值
        this.seatList[index].orgIndex = index
        // 把选择的座位放入到已选座位数组中
        _selectedSeatList.push(this.seatList[index])
      }
    },
    back: function () {
      this.$router.go(-1)
    },
    checkAdjacent: function (selectedSeatList, item) {
      let result = true
      if (selectedSeatList.length > 0) {
        let columnNos = []
        selectedSeatList.forEach(element => {
          if (element.rowNo != item.rowNo) {

            result = false
          }
          columnNos.push(element.columnNo)
        });
        columnNos.sort((a, b) => a - b);
        if ((item.columnNo + 1 == columnNos[0]) || item.columnNo + 1 == columnNos[columnNos.length - 1]) {

          result = true
        } else {
          result = false
        }
      }
      return result;
    },

    thumbnailBackgroud: function (seatItem) {
      if (seatItem.nowIcon === seatItem.selectedIcon) {
        return 'green'
      } else if (seatItem.nowIcon === seatItem.soldedIcon) {
        return 'red'
      } else if (seatItem.nowIcon === seatItem.fixIcon) {
        return 'red'
      } else {
        return 'white'
      }
    },
    getUrlKey: function (name, url) {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20')) || null
    },
    tips(tips) {
      this.ctips = tips
    },
    xgtocom(data) {

      // this.tocom = data
      this.tocreateOrder(true)
    },
    tocreateOrder(data) {
      console.log(3, data)
      this.iscreateOrder = data
    },
    // getFormSeat() {
    //   let setList = []
    //   this.selectedSeatList.forEach(element => {
    //     let seat = {}
    //     seat.seatId = element.seatId
    //     seat.showPrice = element.price
    //     setList.push(seat)
    //   });
    //  return setList
    // }
  },

  computed: {
    // 座位区域高度rem
    seatAreaHeightRem: function () {
      let screenRem = (document.body.clientWidth || window.innerWidth || document.documentElement.clientWidth) / 25
      let height = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight
      // 除了座位区域的大小
      // let otherDom = 1.08 + 1.2 + 1.2 + 1.87 + 0.67 + 1.2// 头+排期信息+座位示例 +底部快捷选择+确认按钮
      let otherDom = 8.25// 头+排期信息+座位示例 +底部快捷选择+确认按钮
      // 剩下的座位区域的大小
      return height / screenRem - otherDom
    },
    // 取最大横坐标
    xMax: function () {
      let i = 0
      for (let index in this.seatList) {
        if (this.seatList[index].columnNo > i) {
          i = this.seatList[index].columnNo
        }
      }
      return i
    },
    // 取最大纵坐标
    yMax: function () {
      let i = 0
      for (let index in this.seatList) {
        if (this.seatList[index].rowNo > i) {
          i = this.seatList[index].rowNo
        }
      }
      return i
    },
    // 竖中轴线
    middleLine: function () {
      return ((this.xMax / 2) + 1) * this.positionDistin - 0.025
    },
    // 横中轴线
    horizontalLine: function () {
      return ((this.yMax / 2) + 1) * this.positionDistin - 0.025
    },
    // 根据影厅的大小缩放比例(需要把影厅全部显示出来)
    seatScale: function () {
      let seatScaleX = 1
      let seatScaleY = 1
      seatScaleX = this.seatAreaWidthRem / this.seatBoxWidth
      seatScaleY = this.seatAreaHeightRem / this.seatBoxHeight
      return seatScaleX < seatScaleY ? seatScaleX * 0.85 : seatScaleY
    },
    // 让影厅居中展示的偏移值
    seatBoxCenterMargin: function () {
      return -(this.seatBoxWidth * this.seatScale) / 2
    },
    // class 为 seatBox 的高度值 单位为rem
    seatBoxHeight: function () {
      return (this.yMax + 1) * this.positionDistin + this.height
    },
    // class 为 seatBox 的宽度值 单位为rem
    seatBoxWidth: function () {
      return (this.xMax + 1) * this.positionDistin + this.width
    },
    // 缩略图宽 rem
    thumbnailBoxWidth: function () {
      return ((this.xMax + 1) * this.thumbnailPositionDistin + this.thumbnailWidth)
    },
    // 缩略图高 rem
    thumbnailBoxHeight: function () {
      return ((this.yMax + 1) * this.thumbnailPositionDistin + this.thumbnailHeight)
    },
    // 快速选择和选择座位组件component on-bind:is的值
    selectedTabComponents: function () {
      return this.selectedSeatList.length > 0 ? 'SelectedTab' : 'QuickSelectTab'
    },
    // 座位左边栏的数组
    seatToolArr: function () {
      let seatToolArr = []
      let yMax = this.yMax
      for (let i = 1; i <= yMax; i++) {
        let el = this.seatList.find((item) => (
          item.rowNo === i
        ))
        if (el) {
          seatToolArr.push(el.rowNo)
          // console.log('--->>>el.row', el.row)
        } else {
          seatToolArr.push('')
        }
      }
      return seatToolArr
    }
  }
}
</script>
<style lang='scss' scoped="scoped">
.wapper {
  width: 100%;
  background: rgb(246, 246, 246);
  overflow: hidden;

  .seat-detail-item {
    display: flex;
    align-content: center;
    margin-top: 0.5rem;
  }

  .seat-detail-item2 {
    display: flex;
    align-content: center;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .seatTypeClass {
    display: block;
    height: 2rem;
    line-height: 2rem;
    white-space: nowrap;
    color: #666;
    font-size: 0.5rem;
  }

  .thumbnailSeatClass {
    position: absolute;
  }

  .seatBox {
    position: absolute;
    left: 50%;
    transform-origin: 0rem 0rem 0rem;

    .middle-line {
      position: absolute;
      border-right: 2px #999 dashed;
    }

    .seatClass {
      position: absolute;

      .seatImgClass {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}

.confirmpd {
  padding: 0 25px;
}

.film-info {
  position: fixed;
  bottom: 9.5rem;
  z-index: 11;
  width: 90%;
  padding-left: 1.2rem;
  margin-right: 1.2rem;
  color: #666;
}

.film-name {
  font-size: 1.1rem;
}

.film-txt1 {
  font-size: 0.8rem;
  margin-right: 0.8rem;
}

.film-txt2 {
  font-size: 0.8rem;
  color: #666;
  margin-right: 0.2rem;
}

.film-txt {
  font-size: 1.2rem;
  margin-top: 0.2rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.05rem rgba(0, 0, 0, 0.2) dashed;
}

.bo-txt {
  border-radius: 1rem 1rem 0 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 14.5rem;
  background: #fff;
  z-index: 11;
}

.bo-seatType {
  border-radius: 1rem 1rem 0 0;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  bottom: 13.5rem;
  background: #fff;
  z-index: 12;
  padding-bottom: 0.5rem;
}

.ml5 {
  margin-left: 0.2rem;
}

.welimg {
  height: 4rem;
  width: 18rem;
}

.wtac {
  text-align: center;
  margin-top: -10px;
  position: relative;
  z-index: -1;
}
</style>
