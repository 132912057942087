var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wapper"},[_c('plan-detail',{attrs:{"propHallName":_vm.hallName,"propShowDate":_vm.showDate,"propShowTime":_vm.showTime}},[_vm._l((_vm.seatsPartition),function(seatTypeItem){return [_c('div',{staticClass:"seat-detail-item"},[_c('img',{staticClass:"seatTypeClass",attrs:{"src":seatTypeItem.icon}}),_c('span',{staticClass:"seatTypeClass ml5"},[_vm._v(_vm._s(_vm.$utils.getPricePoint(seatTypeItem.price)))])])]})],2),_c('seat-area',{ref:"seatArea",attrs:{"propThumbnailAreaWidth":_vm.thumbnailBoxWidth,"propThumbnailAreaHeight":_vm.thumbnailBoxHeight,"propYMax":_vm.yMax,"propSeatScale":_vm.seatScale,"propSeatHeight":_vm.positionDistin,"propSeatToolArr":_vm.seatToolArr,"propSeatAreaWidthRem":_vm.seatAreaWidthRem,"propSeatAreaHeightRem":_vm.seatAreaHeightRem,"propSeatBoxHeight":_vm.seatBoxHeight,"propMiddleLine":_vm.middleLine,"propHorizontalLine":_vm.horizontalLine,"propHallName":_vm.tigTime}},[_c('template',{slot:"thumbnail-seat-solt"},_vm._l((_vm.seatList),function(seatItem,index){return _c('div',[_c('div',{key:'thumbnail' + index,staticClass:"thumbnailSeatClass",style:({
    height: _vm.thumbnailHeight * 2.5 + 'rem',
    width: _vm.thumbnailWidth * 2.5 + 'rem',
    background: _vm.thumbnailBackgroud(seatItem),
    top: seatItem.rowNo * _vm.thumbnailPositionDistin * 2.5 + 'rem',
    left: seatItem.columnNo * _vm.thumbnailPositionDistin * 2.5 + 'rem',
  })})])}),0),_c('template',{slot:"seat-area-solt"},[_c('div',{staticClass:"seatBox",style:({
    transform: 'scale(' + _vm.seatScale + ')',
    height: _vm.seatBoxHeight * 2.5 + 'rem',
    width: _vm.seatBoxWidth * 2.5 + 'rem',
    marginLeft: _vm.seatBoxCenterMargin * 2.5 + 'rem',
  })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.seatList.length > 0),expression:"seatList.length > 0"}],staticClass:"middle-line",style:({ height: _vm.seatBoxHeight * 2.5 + 'rem', left: _vm.middleLine * 2.5 + 'rem' })}),_vm._l((_vm.seatList),function(seatItem,index){return _c('div',[_c('div',{key:index,staticClass:"seatClass",style:({
    height: _vm.height * 2.5 + 'rem',
    width: _vm.width * 2.5 + 'rem',
    top: seatItem.rowNo * _vm.positionDistin * 2.5 + 'rem',
    left: seatItem.columnNo * _vm.positionDistin * 2.5 + 1.25 + 'rem',
  }),on:{"click":function($event){$event.preventDefault();return _vm.clickSeat(index)}}},[_c('img',{staticClass:"seatImgClass",attrs:{"seatId":index,"cNo":seatItem.seatNo,"seatIndex":index,"src":seatItem.nowIcon}})])])})],2)])],2),_c('div',{staticClass:"bo-seatType"},_vm._l((_vm.seatTypeList),function(seatTypeItem){return _c('div',[_c('div',{staticClass:"seat-detail-item2"},[_c('img',{staticClass:"seatTypeClass",attrs:{"src":seatTypeItem.icon}}),_c('span',{staticClass:"seatTypeClass ml5"},[_vm._v(_vm._s(seatTypeItem.name))])])])}),0),_c('div',{staticClass:"bo-txt"},[_c('div',{staticClass:"film-info"},[_c('div',{staticClass:"film-name"},[_vm._v(_vm._s(_vm.movieName))]),_c('div',{staticClass:"film-txt"},[_c('span',{staticClass:"film-txt1"},[_vm._v(_vm._s(_vm.showDate2))]),_c('span',{staticClass:"film-txt2"},[_vm._v(_vm._s(_vm.showTime)+"开始"+_vm._s(_vm.endTime)+"散场")]),_vm._v(" "),_c('span',{staticClass:"film-txt2"},[_vm._v(_vm._s(_vm.show_version_type))])])]),_c('keep-alive',[_c(_vm.selectedTabComponents,{tag:"component",attrs:{"propSeatList":_vm.seatList,"propSelectedSeat":_vm.selectedSeatList},on:{"quickSelect":_vm.processUnSelected,"cancelSelect":_vm.processSelected}})],1),_c('div',{staticClass:"confirmpd"},[_c('confirm-lock',{ref:"confirlock",attrs:{"propSelectedSeat":_vm.selectedSeatList,"propSeatList":_vm.seatList,"propsId":_vm.sId,"propcId":_vm.cId,"propCardId":_vm.form.cardId,"proptips":_vm.ctips,"propfilm_id":_vm.film_id,"propHallName":_vm.hallName,"propShowDate":_vm.showDate,"propShowTime":_vm.showTime,"propEndTime":_vm.endTime,"propcinema":_vm.cinema_name,"propiscOrder":_vm.iscreateOrder,"movieName":_vm.movieName,"showtype":_vm.show_version_type,"movieAddress":_vm.movieAddress,"moviePicUrl":_vm.moviePicUrl},on:{"ctocom":_vm.xgtocom,"showTag":_vm.showTags}})],1)],1),(_vm.showtag)?_c('have-order',{attrs:{"movieDate":_vm.movieDate},on:{"closed":_vm.closeds}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }