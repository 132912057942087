<template>
  <div>
    <van-popup v-model="show" round :style="{ minWidth: '80%' }" :close-on-click-overlay="false">
      <div class="topSty">
        <div class="title"> 存在未支付订单</div>
        <div class="movieImage"> <img class="img" :src="getPic()"> </div>
        <div class="movieName"> {{ getMovieName() }}</div>
        <div class="orderNo"> 订单编号:{{ movieDate?.orderNo }}</div>

        <div class="bottomBox">
          <div class="conte" @click="goBuy">继续支付</div>
        </div>
      </div>
      <div class="conteItem" @click="cancleOrder">
        <div style="font-size: 1rem;border-radius: 2rem;">取消订单</div>
      </div>
    </van-popup>

  </div>
</template>
<script>
export default {
  name: "",
  props: {
    movieDate: null
  },
  data() {
    return {
      show: true,
    };
  },
  components: {},
  methods: {
    getPic() {
      let pic = this.movieDate ? JSON.parse(this.movieDate?.orderDetail).moviePicUrl : ''
      return pic
    },
    getMovieName() {
      let movieName = this.movieDate ? JSON.parse(this.movieDate?.orderDetail).movieName : ''
      return movieName
    },
    goBuy() {
      // if (this.movieDate.wxAmount && this.movieDate.wxAmount > 0) {
      //   this.weiPay()
      // } else {

      let seatList = []
      this.movieDate.seatInfoList.forEach(element => {
        let seatInfo = {
          price: element.showPrice,
          seatId: element.seatId,
          costPrice: element.costPrice
        }
        seatList.push(seatInfo)
      });

      localStorage.setItem('film_id', this.movieDate?.filmId)
      localStorage.setItem("seatList", JSON.stringify(seatList))
      localStorage.setItem("sale_price", this.movieDate?.originalPrice)
      localStorage.setItem('lockseatform', JSON.stringify(this.movieDate))
      let cardId = JSON.parse(this.movieDate?.orderDetail).cardId
      let entrCode = localStorage.getItem('entrCode')
      if (entrCode == 'E3') {
        this.$router.push({
          path: "/MovieCashier",
          query: {
            orderId2:this.movieDate?.orderId,
            order: this.movieDate?.orderNo,
            creatTime: this.movieDate?.orderCreadTime,
            cardId: cardId
          }
        });
      } else
        this.$router.push({
          path: "/Cashier",
          query: {
            orderId2:this.movieDate?.orderId,
            order: this.movieDate?.tripartiteOrderNo,
            creatTime: this.movieDate?.orderCreadTime,
            cardId: cardId
          },
        });
      // }
    },
    weiPay() {
      let mobile = localStorage.getItem('loginphone')
      this.$api
        .confirmWxByMobile(mobile)
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.$Dialog.confirm({
                title: "提示",
                message: "微信支付需要先授权",
                confirmButtonText: '去授权',
                cancelButtonText: '取消'
              })
                .then(() => {
                  this.$router.push({
                    path: "/Personal",
                  });
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.WXPay()
            }
          }
        })
        .catch((err) => {
        });
    },
    WXPay() {
      let form = {
        amount: parseFloat(this.movieDate.wxAmount),
        userId: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : '',
        channelOrderNo: this.movieDate?.orderNo
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
          } else {
            this.init(res.data)
          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    },
    directWeChat(params) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast.success('支付成功');
          } else {
            this.$router.push({
              path: "/AllOrder",
              query: {
                active: 1
              },
            });
          }
        });
    },
    init(params) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },
    cancleOrder() {
      this.$api
        .cancelPayment(this.movieDate.tripartiteOrderNo)
        .then((res) => {
          this.$Toast(res.msg);
          if (res.code == 0) {
            this.closed()
          }
        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    closed() {
      this.$emit("closed");
    },
    change(value) {
      this.check = value
    },

  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.bottomBox {
  margin-top: 1rem;
  margin-bottom: 0.7rem;

  width: 100%;
}

.topSty {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  color: #666;
  background-color: white;
  border-radius: 1rem;
}

.van-popup {
  position: fixed;
  max-height: 100%;
  overflow-y: auto;
  background-color: #00000000;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-overflow-scrolling: touch;
}

.overstyle {
  border-radius: 0.5rem;

}

.title {
  font-size: 1.1rem;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #DDD;
  text-align: center;

}

.img {
  width: 6.85rem;
  height: 9.14rem;
  min-width: 6.85rem;
  min-height: 9.14rem;
  border-radius: 0.5rem;
}


.orderNo {

  width: 100%;
  padding: 0.5rem 0;
  text-align: center;

}

.movieName {

  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1rem;
}

.movieImage {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.conteItem {
  margin: 0 auto;
  color: #ddd;
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
}

.conte {
  border-radius: 2rem;
  width: 95%;
  // width: 100%;
  text-align: center;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  font-weight: 600;
  color: white;
  font-size: 1rem;
  margin: 0 auto;
}

.btn {
  height: 2.5rem;
  margin-top: 0.5rem;
}
</style>